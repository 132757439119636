import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "@fontsource/playfair-display" 
import { useStaticQuery , graphql } from "gatsby"

export default function Destinations() {

  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          # filter: {frontmatter: {parent_page: {eq: null}}}
          sort: { fields: [frontmatter___parent_page, frontmatter___sort_order] }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                page_id
                title
                sort_order
                parent_page
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  )

  return (
    <div className="md:divide-y-2">
        {
          data.allMarkdownRemark.edges.filter( ({ node }) => node.frontmatter.parent_page === `reisemal` ).map( ({ node }) => 
            <div className="md:flex md:space-x-4 py-3 md:mx-0">
              <div className="md:flex-shrink-0 md:w-1/2">
                <StaticImage src="../media/lofoten/IMG_0201.jpg" alt="Destinasjon" />
              </div>
              <div className="p-6">
                <h2 className="font-heading text-2xl">{ node.frontmatter.title }</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                  ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            </div>
          )
        }
    </div>
  )
}
