import React from "react"
import { Link } from "gatsby"


export default function DestinationPromo({ children , text , text2 , link }) {

  return (
    <Link to={ link } className="relative overflow-hidden group md:w-1/3">
      <figure>
        { children }
        <figcaption className="absolute -mt-48 font-heading text-5xl text-white px-6 w-full" >
          { text } <br/> { text2 }
        </figcaption>
      </figure>
    </Link>
  )
}
