import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import DestinationPromo from "../components/destination_promo"

export default function Hero() {

  return (
    <div className="md:flex md:space-x-6">
      <DestinationPromo text="Flåm" text2="Aurland" link="/reisemal/flom-aurland">      
        <StaticImage src="../media/aurland/IMG_0099.jpg" aspectRatio={12/15} className="group-hover:transform group-hover:scale-105 duration-300 ease-in-out" alt="Aurland" />
      </DestinationPromo>

      <DestinationPromo text="Sunnmøre" link="/reisemal/sunnmore">
        <StaticImage src="../media/lofoten/IMG_0201.jpg" aspectRatio={12/15} className="group-hover:transform group-hover:scale-105 duration-300 ease-in-out" alt="Lofoten" />
      </DestinationPromo>

      <DestinationPromo text="Lofoten" text2="Aktiv Weekend" link="/reisemal/lofoten">
        <StaticImage src="../media/lofoten/90a5771f-9563-4fd9-9274-63e3786935f2.jpg" aspectRatio={12/15} className="group-hover:transform group-hover:scale-105 duration-300 ease-in-out" alt="Lofoten" />
      </DestinationPromo>
    </div>
  )
}