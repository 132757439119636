import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Destinations from "../components/destinations"

export default function Home() {
  return (
    <Layout>
      <div className="flex justify-around pb-8 text-lg">
        <div className="font-script italic md:text-2xl">Skreddersydde, aktive naturopplevelser</div>
      </div>
      <Hero />
      <Destinations />
    </Layout>
  )
}
